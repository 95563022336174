export const DE_TRANSLATIONS = {
  SERVICE_NAME: "Energy Monitoring",
  BROWSER_TAB_TITLE: "myBKW: Energiemonitoring",
  UNIT: {
    KW: "{{value}} kW",
    KWH: "{{value}} kWh",
  },
  SERVICE: {
    SUBSCRIPTION: {
      SUBMIT: {
        SUCCESS: "Änderungen wurden erfolgreich gespeichert.",
      },
      GENERAL: {
        ERROR: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut. Sollte der Service weiterhin nicht verfügbar sein, melden Sie sich bitte bei uns unter <a href=\"mailto:smartmeter@bkw.ch\">smartmeter@bkw.ch</a>",
      },
    },
  },
  BFC_UNAUTHORIZED: {
    TITLE: "Zugriff verweigert",
    CONTENT: "Sie haben keine Berechtigung die Daten dieses Smart Meters abzurufen.",
  },
  ENERGY_MONITORING: {
    MINUTES_15: "15-Minuten",
    DAY: "Tag",
    MONTH: "Monat",
    YEAR: "Jahr",
  },
  GENERIC_REPORT: {
    START_DATE: "Startdatum",
    START_TIME: "Startzeit",
    END_DATE: "Enddatum",
    END_TIME: "Endzeit",
    EXPORT: "Daten exportieren",
    HINT: {
      DAYLIGHT_SAVING: "Hinweis: Zeitumstellung",
      DAYLIGHT_SAVING_ENTERED: "An diesem Tag fand zwischen 02:00-03:00 Uhr morgens die Umstellung von Winter- auf Sommerzeit statt. Somit fand die Stunde zwischen 02:00-03:00 an diesem Tag nicht statt und der Tag hatte insgesamt 23 Stunden.",
      DAYLIGHT_SAVING_EXITED: "An diesem Tag fand zwischen 02:00-03:00 Uhr morgens die Umstellung von Sommer- auf Winterzeit statt. Somit fand die Stunde zwischen 02:00-03:00 an diesem Tag zweimal statt und der Tag hatte insgesamt 25 Stunden.",
    },
    COMPARE: {
      TITLE: "Vergleichsperiode",
      TIMERANGE_START: "Von (Datum)",
      TIMERANGE_START_TIME: "Von (Zeit)",
      TIMERANGE_END: "Bis (automatisch)",
      REMOVE_BUTTON: "Entfernen",
    },
  },
  GRAPH_COMPONENT: {
    CLOCK: "Uhr",
    CHART: {
      LABEL: {
        CONSUMPTION_ENERGY: "Strombezug",
        CONSUMPTION_POWER: "Bezogene Leistung",
        PRODUCTION_ENERGY: "Rücklieferung",
        PRODUCTION_POWER: "Einspeiseleistung",
        CONSUMPTION_COMPARISON_ENERGY: "Strombezug Vergleichsperiode",
        CONSUMPTION_COMPARISON_POWER: "Bezogene Leistung Vergleichsperiode",
        PRODUCTION_COMPARISON_ENERGY: "Rücklieferung Vergleichsperiode",
        PRODUCTION_COMPARISON_POWER: "Einspeiseleistung Vergleichsperiode",
        PMAX: "Leistungsmaximum",
      },
      MENU: {
        VIEW_FULLSCREEN: "Vollbildmodus",
        PRINT_CHART: "Grafik ausdrucken",
        DOWNLOAD_PNG: "Grafik als PNG herunterladen",
        DOWNLOAD_JPEG: "Grafik als JPEG herunterladen",
        DOWNLOAD_PDF: "Grafik als PDF herunterladen",
        DOWNLOAD_SVG: "Grafik als SVG herunterladen",
      },
      INFO_BOX: {
        CONSUMPTION_ENERGY: "Ihr Strombezug ist die Strommenge (kWh), die Sie am gewählten Messpunkt aus dem Stromnetz beziehen.",
        CONSUMPTION_POWER: "Die bezogene Leistung (kW) gibt an, welche Netzkapazität Sie am gewählten Messpunkt für Ihren Strombezug in Anspruch nehmen.",
        PRODUCTION_ENERGY: "Ihre Rücklieferung ist die selbst produzierte Strommenge (kWh), die Sie am gewählten Messpunkt in das Stromnetz einspeisen.",
        PRODUCTION_POWER: "Die Einspeiseleistung (kW) gibt an, welche Netzkapazität Sie am gewählten Messpunkt in Anspruch nehmen, um Ihre Rücklieferung in das Stromnetz einzuspeisen.",
      },
      RADIO_BUTTON: {
        CONSUMPTION_ENERGY: "Strombezug",
        PRODUCTION_ENERGY: "Rücklieferung",
        CONSUMPTION_POWER: "Bezogene Leistung",
        PRODUCTION_POWER: "Einspeiseleistung",
      },
    },
  },
  DATASHEET_COMPONENT: {
    TITLE_CONSUMPTION: "Übersicht Strombezug & Leistungsmaximum",
    TITLE_PRODUCTION: "Übersicht Rücklieferung & Leistungsmaximum",
    CONSUMPTION: "Strombezug",
    PRODUCTION: "Rücklieferung",
    COMPARISON_CONSUMPTION: "Vergleich Strombezug",
    COMPARISON_PRODUCTION: "Vergleich Rücklieferung",
    DESCRIPTION: "Die nachfolgenden Werte fassen den ausgewählten Zeitraum auf der Grafik zusammen.",
    TOTAL_CONSUMPTION: "Strombezug Total",
    TOTAL_PRODUCTION: "Rücklieferung Total",
    PMAX: "Leistungsmaximum",
    PMAX_TOOLTIP: "Das Leistungsmaximum, auch «pmax» genannt, gibt den höchsten Leistungsbezug innerhalb des von Ihnen ausgewählten Zeitraums an. Der Leistungsbezug ist ein berechneter Wert, der anhand von viertelstündigen Verbrauchswerten ermittelt wird.",
  },
  OVERVIEW_LABELS: {
    BACK_TO_METER_SELECTION: "« zurück zur Übersicht",
    BACK_TO_MY_BKW_ENERGY: "« zurück zur Übersicht",
    TITLE: "Mein Energiemonitoring",
    TEXT_UNDER_TITLE: "Hier können Sie Ihren Strombezug und Ihre Rücklieferung auf die Viertelstunde genau ermitteln, immer auf den Vortag aktuell.",
    DISCLAIMER: "Disclaimer: Die Grafik stellt die Strombezugswerte, die Rücklieferung, die bezogene Leistung oder die Einspeiseleistung des Kunden dar. Sie dient lediglich zu Informationszwecken und ist nicht verbindlich. Massgebend sind ausschliesslich die im Rahmen der Abrechnung des Strombezugs verwendeten plausibilisierten Messdaten.",
  },
  SELECTION: {
    TITLE: "Mein Energiemonitoring",
    TABLE: {
      HEADER: {
        METERING_CODE: "Messpunkt",
      },
    },
    BACK_TO_ADMIN_OVERVIEW: "« zurück zur Admin Overview",
  },
  ADMIN: {
    TITLE: "Energiemonitoring Admin",
    DESCRIPTION: "Starten Sie eine Impersonierung mit der Geschäftspartner Nummer des Kunden",
    INPUT: {
      PLACEHOLDER_GP_NUMBER: "GP Nummer",
      HINT_GP_NUMBER: "GP Nummer ist 8 Zeichen lang",
    },
    IMPERSONATION_LABEL: "Impersoniert als GP: ",
    BUTTON: {
      LABEL: "Impersonierung starten",
    },
  },
  CSV_EXPORT: {
    NOTIFICATION: {
      SUCCESS: "Der Download war erfolgreich",
    },
    DIALOG: {
      TITLE: "CSV-Datei herunterladen",
      DESCRIPTION: "Hier können Sie die Daten als CSV-Datei in 15-Minuten Werten herunterladen.",
      DATEPICKER: {
        START_DATE: "Startdatum",
        END_DATE: "Enddatum",
      },
      BUTTON: {
        CANCEL: "Abbrechen",
        DOWNLOAD: "Herunterladen",
      },
    },
    COLUMN: {
      DATE: "Datum",
      CONSUMPTION: {
        ENERGY: "Strombezug [kWh]",
        POWER: "Bezogene Leistung [kW]",
      },
      PRODUCTION: {
        ENERGY: "Rücklieferung [kWh]",
        POWER: "Einspeiseleistung [kW]",
      },
    },
    FIRST_ROW: {
      METERING_POINT: "Messpunkt:",
    },
  },
};