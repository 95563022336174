import { Injectable } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import * as Highstock from "highcharts/highstock";
import { XAxisLabelsOptions } from "highcharts/highstock";
import { AxisTypeValue, ResponsiveOptions, TooltipOptions, XAxisOptions, YAxisOptions } from "highcharts";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { DecimalPipe } from "@angular/common";
import { GraphComponent } from "../graphs/graph.component";
import { GraphOptions } from "../graphs/graph-options";
import * as moment from "moment";
import { Moment } from "moment";
import { ChartIntervalTypeEnum } from "../core/model/chart-interval-type.enum";
import { DataType } from "../core/model/data-type";
import { TimeSeriesCollection } from "../core/model/time-series-collection";
import {
  COLOR_CHART_CONSUMPTION_COMPARE_LIGHT_BLUE,
  COLOR_CHART_CONSUMPTION_DARK_BLUE,
  COLOR_CHART_PRODUCTION_COMPARE_LIGHT_GREEN,
  COLOR_CHART_PRODUCTION_DARK_GREEN,
} from "../core/model/color.constant";
import { MeteringPointsInformationDto } from "../generated/energy-monitoring/model/meteringPointsInformationDto";
import { TimeSeriesGraph } from "../core/model/time-series-graph";
import { TimeSeriesUnitType } from "../core/model/time-series-unit-type";

@Injectable()
export class GraphsLayoutService {

  private readonly textColor: string = "#222";

  private readonly fontSize: string = "14px";

  private readonly marginLeftDesktop = 75;

  private readonly marginLeftMobile = 40;

  private readonly oclockText: string = this.bfcTranslationService.translate("GRAPH_COMPONENT.CLOCK");

  private readonly yearFormat: string = this.bfcConfigurationService.configuration.yearFormat;

  private readonly monthFormat: string = this.bfcConfigurationService.configuration.monthFormat;

  private readonly dailyFormat: string = this.bfcConfigurationService.configuration.dailyFormat;

  private readonly fullDateFormat: string = this.bfcConfigurationService.configuration.fullDateFormat;

  private readonly endQuarterHourFormat: string = this.bfcConfigurationService.configuration.endQuarterHourFormat;

  private readonly thresholdWidthSmartphoneInPx: number = 600;

  constructor(private bfcTranslationService: BfcTranslationService,
    private bfcConfigurationService: BfcConfigurationService,
    private decimalPipe: DecimalPipe) {
  }

  /**
   * get options for highstock charts graph of type COLUMN and time based series
   * @param timeSeriesGraph
   * @param that
   * @param subtitle
   * @param xAxisType
   * @param graphOptions
   * @param meteringPointInfo
   */
  combineLayoutWithDataColumnChart(timeSeriesGraph: TimeSeriesGraph, that: GraphComponent,
    xAxisType: string, graphOptions: GraphOptions, meteringPointInfo: MeteringPointsInformationDto)
    : Highstock.Options {
    const options: any = {
      chart: {
        zoomType: graphOptions.withoutSlider ? undefined : "x",
        marginLeft: this.isSmartphone() ? this.marginLeftMobile : this.marginLeftDesktop,
        spacingLeft: 0,
        spacingRight: 24,
        height: graphOptions.withoutSlider ? 420 : 600,
        type: timeSeriesGraph.unitType === TimeSeriesUnitType.ENERGY ? "column" : "line",
        spacingTop: 10,
        style: {
          fontSize: this.isSmartphone() ? "13px" : "18px",
          fontWeight: "normal",
          fontFamily: "bkw-regular-font",
        },
        events: timeSeriesGraph.unitType === TimeSeriesUnitType.POWER ? this.addPMaxLine() : {},
      },
      scrollbar: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: meteringPointInfo.meteringPointCode,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: this.getContextMenuItems(),
          },
        },
        menuItemDefinitions: {
          viewFullscreen: {
            text: this.bfcTranslationService.translate("GRAPH_COMPONENT.CHART.MENU.VIEW_FULLSCREEN"),
          },
          printChart: {
            text: this.bfcTranslationService.translate("GRAPH_COMPONENT.CHART.MENU.PRINT_CHART"),
          },
          downloadPNG: {
            text: this.bfcTranslationService.translate("GRAPH_COMPONENT.CHART.MENU.DOWNLOAD_PNG"),
          },
          downloadJPEG: {
            text: this.bfcTranslationService.translate("GRAPH_COMPONENT.CHART.MENU.DOWNLOAD_JPEG"),
          },
          downloadPDF: {
            text: this.bfcTranslationService.translate("GRAPH_COMPONENT.CHART.MENU.DOWNLOAD_PDF"),
          },
          downloadSVG: {
            text: this.bfcTranslationService.translate("GRAPH_COMPONENT.CHART.MENU.DOWNLOAD_SVG"),
          },
        },
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          showInNavigator: true,
          marker: {
            enabled: false,
            fillColor: "#002D69",
            lineWidth: 0,
            symbol: "circle",
          },
          step: "left",
          dataGrouping: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      noData: {
        style: {
          fontSize: "16px",
          fontFamily: "bkw-regular-font",
        },
      },
      navigator: {
        enabled: !graphOptions.withoutSlider,
      },
      legend: this.getLegend(),
      tooltip: this.getTooltip(that, graphOptions),
      xAxis: this.getXAxisOptions(xAxisType),
      yAxis: this.getYAxisOptions(true, graphOptions.isReport),
      responsive: this.getResponsive(),
      rangeSelector: {
        enabled: false,
      },
      series: this.getSeries(timeSeriesGraph),
    };

    return options;
  }

  private getContextMenuItems(): string[] {
    let contextMenuItems = ["viewFullscreen", "printChart", "separator",
      "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"];

    if (this.isIPhone()) {
      // https://api.highcharts.com/class-reference/Highcharts.Fullscreen#:~:text=Fullscreen-,new%20Fullscreen(),iPhone%20due%20to%20iOS%20limitations.
      contextMenuItems = contextMenuItems.filter(item => item !== "viewFullscreen");
    }

    return contextMenuItems;
  }

  private getSeries(timeSeriesGraph: TimeSeriesGraph) {
    const series = [];

    const seriesMap: Map<string, TimeSeriesCollection> = timeSeriesGraph.graphData;
    const timeSeriesUnitType: TimeSeriesUnitType = timeSeriesGraph.unitType;

    if (seriesMap.has(DataType.CONSUMPTION)) {
      const label = timeSeriesUnitType === TimeSeriesUnitType.ENERGY ? 
        "GRAPH_COMPONENT.CHART.LABEL.CONSUMPTION_ENERGY" : "GRAPH_COMPONENT.CHART.LABEL.CONSUMPTION_POWER"; 

      series.push(this.getSerie(seriesMap.get(DataType.CONSUMPTION),
        label, COLOR_CHART_CONSUMPTION_DARK_BLUE));
    }
    if (seriesMap.has(DataType.PRODUCTION)) {
      const label = timeSeriesUnitType === TimeSeriesUnitType.ENERGY ? 
        "GRAPH_COMPONENT.CHART.LABEL.PRODUCTION_ENERGY" : "GRAPH_COMPONENT.CHART.LABEL.PRODUCTION_POWER";

      series.push(this.getSerie(seriesMap.get(DataType.PRODUCTION),
        label, COLOR_CHART_PRODUCTION_DARK_GREEN));
    }
    if (seriesMap.has("Comp:" + DataType.CONSUMPTION)) {
      const label = timeSeriesUnitType === TimeSeriesUnitType.ENERGY ? "GRAPH_COMPONENT.CHART.LABEL.CONSUMPTION_COMPARISON_ENERGY" : "GRAPH_COMPONENT.CHART.LABEL.CONSUMPTION_COMPARISON_POWER";

      series.push(this.getSerie(seriesMap.get("Comp:" + DataType.CONSUMPTION),
        label, COLOR_CHART_CONSUMPTION_COMPARE_LIGHT_BLUE));
    }
    if (seriesMap.has("Comp:" + DataType.PRODUCTION)) {
      const label = timeSeriesUnitType === TimeSeriesUnitType.ENERGY ? "GRAPH_COMPONENT.CHART.LABEL.PRODUCTION_COMPARISON_ENERGY" : "GRAPH_COMPONENT.CHART.LABEL.PRODUCTION_COMPARISON_POWER";
      
      series.push(this.getSerie(seriesMap.get("Comp:" + DataType.PRODUCTION),
        label, COLOR_CHART_PRODUCTION_COMPARE_LIGHT_GREEN));
    }
    return series;
  }

  private getLegend() {
    return {
      enabled: true,
      padding: 0,
      margin: 24,
      align: "center", // left
      x: this.isSmartphone() ? this.marginLeftMobile : this.marginLeftDesktop,
      itemMarginBottom: 5,
      itemStyle: { // could not be overridden via scss, as of version 6.1.0
        fontSize: this.isSmartphone() ? "13px" : "18px",
        fontWeight: "normal",
        fontColor: this.textColor,
        fontFamily: "bkw-regular-font",
      },
      symbolRadius: 0,
      useHTML: true,
    };
  }

  private getSerie(timeSeriesCollection: TimeSeriesCollection, nameTranslationKey: string, color: string = null) {
    return {
      data: timeSeriesCollection.data,
      visible: true,
      color: color,
      name: this.bfcTranslationService.translate(nameTranslationKey),
    };
  }

  private getResponsive(): ResponsiveOptions {

    return {
      rules: [
        {
          condition: {
            maxWidth: 599,
          },
          chartOptions:
            {
              chart: {
                marginLeft: this.marginLeftMobile,
              },
            },
        },
      ],
    };
  }

  private getXAxisOptions(xAxisType: string): XAxisOptions {
    return {
      gridLineWidth: 1,
      type: xAxisType as AxisTypeValue,
      dateTimeLabelFormats: {
        month: "%B %Y",
        year: "%Y",
      },
      title: {
        style: {
          color: this.textColor,
        },
        text: null,
      },
      crosshair: {
        color: "#002D69",
        snap: true,
        width: 1,
        zIndex: 50,
      },
    };
  }

  private getAxisLabelsOptions(formatting: boolean): XAxisLabelsOptions {
    if (!!formatting) {
      return {
        style: {
          fontSize: this.fontSize,
          color: this.textColor,
        },
      };
    } else {
      return {
        style: {
          fontSize: this.fontSize,
          color: this.textColor,
        },
      };
    }
  }

  private getYAxisOptions(formatting: boolean = false, isReport: boolean = false): YAxisOptions {
    return {
      gridLineWidth: 1,
      labels: this.getAxisLabelsOptions(formatting),
      showFirstLabel: false,
      title: {
        align: "high",
        offset: 12,
        rotation: 0,
        y: isReport ? 6 : -12,
        text: "",
        style: {
          color: this.textColor,

        },
      },
      opposite: false,
    };
  }

  private getTooltip(that, graphOptions:GraphOptions) : TooltipOptions {
    const localString = this.getLangLocalString();
    const fullDateFormat = this.fullDateFormat;
    const endQuarterHourFormat = this.endQuarterHourFormat;
    const oclockText = this.oclockText;
    const yearFormat = this.yearFormat;
    const monthFormat = this.monthFormat;
    const dailyFormat = this.dailyFormat;
    const decimalPipe = this.decimalPipe;
    const bfcConfigurationService = this.bfcConfigurationService;
    const numberFormat = graphOptions.intervalGraphType === ChartIntervalTypeEnum.MINUTES_GRAPH ||
    graphOptions.intervalGraphType === ChartIntervalTypeEnum.DAILY_GRAPH
      ? bfcConfigurationService.configuration.twoDigitsFormat
      : bfcConfigurationService.configuration.zeroDigitsFormat;

    return {
      formatter: function () {
        const current = {
          x: this.x,
          y: this.y,
          points: this.points,
        };

        // Prepare HTML output
        let html: string = "";
        html += "<div class=\"graph-tooltip\" >";
        html += "<div class=\"headline\">";

        if (graphOptions.intervalGraphType === ChartIntervalTypeEnum.MINUTES_GRAPH) {
          const startDate: Moment =  moment(current.points[0].point.x).locale(localString);
          const startString: string = startDate.format(fullDateFormat);
          const endString: string = startDate.add(15, "minutes").format(endQuarterHourFormat);

          html += `${startString} - ${endString} ${oclockText}`;
        } else if (graphOptions.intervalGraphType === ChartIntervalTypeEnum.MONTH_GRAPH) {
          html += moment(current.points[0].point.x).locale(localString).format(monthFormat);
        } else if (graphOptions.intervalGraphType === ChartIntervalTypeEnum.DAILY_GRAPH) {
          html += moment(current.points[0].point.x).locale(localString).format(dailyFormat);
        } else if (graphOptions.intervalGraphType === ChartIntervalTypeEnum.YEAR_GRAPH) {
          html += moment(current.points[0].point.x).locale(localString).format(yearFormat);
        }
        html += "</div>";

        html += "<div  >";
        current.points.forEach((point: any) => {
          let axisTitle = "";
          if (point.series.yAxis && point.series.yAxis.axisTitle) {
            axisTitle = point.series.yAxis.axisTitle.textStr;
          }

          html += "<div class=\"serie\" >" + point.series.name + "</div>";
          html += "<div class=\"value-unit\" style=\"color:" + point.color + ";font-size: 1.3em\">" +
              decimalPipe.transform(point.y, numberFormat, localString) + " " + axisTitle;
          html += "</div>";
        });
        html += "</div>";
        html += "</div>";

        return html;
      },
      useHTML: true,
      shared: true,
      split: false,
      backgroundColor: "rgba(255,255,255,0.75)",
      borderWidth: 0,
      padding: 0,
      shadow: false,
      followPointer: false,
      followTouchMove: false,
      style: {},
      positioner: (labelWidth, labelHeight, point) => {
        let tooltipX, tooltipY;
        if (point.plotX + labelWidth > that.chart.plotWidth) {
          tooltipX = point.plotX + that.chart.plotLeft - labelWidth - 6;
        } else {
          tooltipX = point.plotX + that.chart.plotLeft + 6;
        }

        tooltipY = that.chart.plotSizeY - 282;
        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
      shape: "square",
    };

  }

  private getLangLocalString(): string {
    return this.bfcTranslationService.language.toLocaleLowerCase() + "-CH";
  }

  private isSmartphone(): boolean {
    // https://github.com/angular/flex-layout/blob/master/docs/documentation/Responsive-API.md
    return window.innerWidth < this.thresholdWidthSmartphoneInPx;
  }

  private isIPhone(): boolean {
    return /iPhone/i.test(navigator.userAgent);
  }

  private addPMaxLine(): any {
    const addPMaxPlotLine = (chart) => {
      const yAxis = chart.yAxis[0];
      yAxis.removePlotLine();

      // add default pMax line
      if (chart.series.length >= 2) {
        yAxis.addPlotLine({
          value: chart.series[0].dataMax,
          color: chart.series[0].color,
          width: 2,
          label: {
            text: this.bfcTranslationService.translate("GRAPH_COMPONENT.CHART.LABEL.PMAX"),
            style: {
              fontSize: this.isSmartphone() ? "13px" : "18px",
              color: chart.series[0].color,
            },
            x: 30,
          },
          zIndex: 5,
        });
      }

      // add compared pMax line
      if (chart.series.length >= 4) {
        yAxis.addPlotLine({
          value: chart.series[1].dataMax,
          color: chart.series[1].color,
          width: 2,
          label: {
            text: this.bfcTranslationService.translate("GRAPH_COMPONENT.CHART.LABEL.PMAX"),
            style: {
              fontSize: this.isSmartphone() ? "13px" : "18px",
              color: chart.series[1].color,
            },
            x: 30,
          },
          zIndex: 5,
        });
      }
    };

    return {
      redraw: function () {
        addPMaxPlotLine(this);
      },
      load: function () {
        addPMaxPlotLine(this);
      },
    };
  }
}
